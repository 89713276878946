import { FC } from 'react';

import { FiHelpCircle, FiLock, FiUser } from 'react-icons/fi';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { routes } from '~/shared/constants/routes';

import { useAuth } from '~/modules/auth/hooks/useAuth';

import { FormGroup } from '~/shared/components/Form/FormGroup';
import { ControlledInput } from '~/shared/components/Form/ControlledInput';
import { Button } from '~/shared/components/Button';
import { AuthLink } from '~/shared/components/AuthLink';
import { AuthHeader } from '~/shared/components/AuthHeader';

interface IFormData {
  email: string;
  password: string;
}

const schemaValidation = yup.object().shape({
  email: yup
    .string()
    .email('O formato do e-mail está incorreto')
    .required('E-mail é obrigatório'),
  password: yup.string().required('Senha é obrigatório'),
});

export const SignIn: FC = () => {
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<IFormData>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schemaValidation),
  });

  const { signIn } = useAuth();

  const handleSignIn = handleSubmit(signIn);

  return (
    <>
      <AuthHeader
        title="Seja bem vindo"
        subtitle="Faça o seu login para entrar no portal"
      />

      <form onSubmit={handleSignIn}>
        <FormGroup>
          <ControlledInput
            control={control}
            icon={FiUser}
            name="email"
            placeholder="Digite seu o e-mail"
            error={errors.email}
            autoComplete="email"
            size="lg"
          />
        </FormGroup>

        <FormGroup>
          <ControlledInput
            control={control}
            icon={FiLock}
            name="password"
            type="password"
            placeholder="Digite a sua senha"
            error={errors.password}
            autoComplete="current-password"
            size="lg"
          />
        </FormGroup>

        <Button type="submit" mt="md" size="lg" isLoading={isSubmitting}>
          Entrar
        </Button>

        <AuthLink
          href={routes.AUTH.PASSWORD_RECOVERY}
          icon={FiHelpCircle}
          title="Esqueci minha senha"
        />
      </form>
    </>
  );
};
